import { AxiosResponse } from 'axios';
import getConfig from 'next/config';
import router from 'next/router';
import { stringify } from 'qs';

import {
    PublicRuntimeConfig,
    ServerRuntimeConfig,
} from '../../next.config.types';
import { upsertShoppingBasketCookie } from '../cookies';
import {
    DayPassAvailability,
    GymProduct,
    GymStatus,
    Product,
    ProductVariant,
} from '@tgg/common-types';
import { axiosInstance } from '@tgg/micro-services/axios';
import {
    dispatchEvent,
    EventKey,
    JoinResponseBody,
    ProductViewEvent,
} from '@tgg/services';
import { OptimizelyEvent, OptimizelyEvents } from '@tgg/util';

const {
    publicRuntimeConfig: { APP_BASE_PATH },
    serverRuntimeConfig: { APP_INTERNAL_BASE_URL },
}: {
    publicRuntimeConfig: PublicRuntimeConfig;
    serverRuntimeConfig: ServerRuntimeConfig;
} = getConfig();

const domain = `${APP_INTERNAL_BASE_URL}${APP_BASE_PATH}`;

export async function getProductsAvailableAddons(
    branchId: string,
    products: GymProduct[] = [],
): Promise<{ [productId: string]: boolean }> {
    return Object.fromEntries(
        await Promise.all<[string, boolean]>(
            products
                .filter(product => product.variant === ProductVariant.Monthly)
                .map(async product => {
                    const addonsResponse: AxiosResponse<JoinResponseBody> =
                        await axiosInstance.get(
                            `${domain}/api/proxy/join/options/membership/`,
                            {
                                params: {
                                    BranchId: branchId,
                                    AccountTemplateIds: [product.id],
                                },
                                paramsSerializer(parameters) {
                                    return stringify(parameters, {
                                        arrayFormat: 'repeat',
                                    });
                                },
                            },
                        );

                    const {
                        data: { availableAddons },
                    } = addonsResponse;

                    return [product.id, availableAddons.length > 0] as [
                        string,
                        boolean,
                    ];
                }),
        ),
    );
}

export const handleProductSelection = async (
    product: Product,
    branchId: string,
    gymName: string,
    branchStatus?: GymStatus,
    dayPassStartDate?: DayPassAvailability,
    trackFlagsEvent?: (enent: OptimizelyEvent) => void,
) => {
    const {
        id,
        description,
        price,
        expectedPaymentFrequency,
        variant,
        membershipType,
    } = product;

    upsertShoppingBasketCookie({
        branchId,
        branchStatus,
        basketItems: {
            product: {
                id,
                description,
                expectedPaymentFrequency,
                membershipType,
            },
            addOnIds: [],
        },
        payments: {},
        ...(dayPassStartDate
            ? {
                  membershipStartDate: dayPassStartDate.startDate,
              }
            : {}),
    });

    dispatchEvent<ProductViewEvent>(EventKey.ADD_TO_BASKET, {
        event: EventKey.ADD_TO_BASKET,
        ecommerce: {
            add: {
                products: [
                    {
                        name: description,
                        price: price.value,
                        brand: gymName,
                        category: expectedPaymentFrequency,
                        variant: 'Create',
                        quantity: 1,
                    },
                ],
            },
        },
    });

    // we only need to router.push for day pass selection because all other product CTAs are handled by next Link
    if (variant === 'DayPass') {
        if (trackFlagsEvent)
            trackFlagsEvent(OptimizelyEvents.START_JOIN_JOURNEY);
        await router.push('/join-now/your-membership/');
    }
};
