import { createWinstonLogger } from '../logger/serverLogger/winstonLoggerInstance';

export type ServerLoggerLegacy = ReturnType<typeof createServerLoggerLegacy>;

export const createServerLoggerLegacy = (
    app: string,
    env: string,
    loggingLevel: string,
) => {
    const winstonLogger = createWinstonLogger(env, loggingLevel);
    return {
        out(type: string, message: string, label = 'root') {
            winstonLogger.log({
                level: type,
                message,
                correlation_id: label,
                application: app,
            });
        },
        error(message: string, label = 'root') {
            winstonLogger.log({
                level: 'error',
                message,
                correlation_id: label,
                application: app,
            });
        },
        warn(message: string, label = 'root') {
            winstonLogger.log({
                level: 'warn',
                message,
                correlation_id: label,
                application: app,
            });
        },
        info(message: string, label = 'root') {
            winstonLogger.log({
                level: 'info',
                message,
                correlation_id: label,
                application: app,
            });
        },
        debug(message: string, label = 'root') {
            winstonLogger.log({
                level: 'debug',
                message,
                correlation_id: label,
                application: app,
            });
        },
        stream: {
            write(message: string) {
                winstonLogger.info(message);
            },
        },
    };
};
