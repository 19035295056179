import getConfig from 'next/config';

import { createServerLoggerLegacy } from '@tgg/util';

const {
    serverRuntimeConfig: { NODE_ENV, APP_SERVER_LOGGING_LEVEL },
} = getConfig();

export const serverLogger = createServerLoggerLegacy(
    'TGG Commerce - Next.js serverside',
    NODE_ENV,
    APP_SERVER_LOGGING_LEVEL,
);
