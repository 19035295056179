import { v4 as uuidv4 } from 'uuid';

import { InformationProperties } from './Information.types';
import { CmsContent, Container } from '@tgg/ui';

export function Information({ titleBox, content }: InformationProperties) {
    return (
        <>
            <CmsContent schema={titleBox} />
            <Container>
                {content.map(contentItem => (
                    <CmsContent
                        /* eslint-disable-next-line no-underscore-dangle */
                        key={uuidv4()}
                        schema={contentItem}
                    />
                ))}
            </Container>
        </>
    );
}
