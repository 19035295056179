import { AxiosResponse } from 'axios';
import { GetServerSidePropsContext } from 'next';
import getConfig from 'next/config';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
    getShoppingBasketCookie,
    updateShoppingBasketCookie,
} from '../../core/cookies';
import { LocalConfig, PublicRuntimeConfig } from '../../next.config.types';
import { GymPageProperties, JoinOptionsResponse } from './GymPage.types';
import {
    CmsPage,
    DayPassAvailability,
    GymProduct,
    PlaceRating,
    Product,
    ProductVariant,
} from '@tgg/common-types';
import { axiosInstance } from '@tgg/micro-services/axios';
import {
    dispatchEvent,
    EventKey,
    getPlaceRating,
    getTrustpilotData,
    GymDto,
    ProductViewEvent,
} from '@tgg/services';
import { CmsContent, getLoggerPaymentData, useFlagsContext } from '@tgg/ui';
import {
    OptimizelyEvents,
    formatProductsForUI,
    getFromPriceForProducts,
} from '@tgg/util';
import { serverApplicationIdentifier } from 'apps/commerce/core/constants';
import {
    getProductsAvailableAddons,
    handleProductSelection,
} from 'apps/commerce/core/products';
import { serverLogger } from 'apps/commerce/core/serverLogger/serverLogger';

const {
    publicRuntimeConfig: { GOOGLE_RATING_WIDGET_ENABLED },
}: {
    publicRuntimeConfig: PublicRuntimeConfig;
} = getConfig();

const MINIMUM_RATING_TO_SHOW = 4;

export function GymPage({
    branchId,
    gymName,
    products,
    trustpilotData,
    address,
    openingHours,
    latitude,
    longitude,
    content,
    gym,
    productsAvailableAddons,
    offPeakTimes,
    isKickerOffersRibbonEnabled,
    gymRating,
    offSale,
    branchStatus,
}: GymPageProperties) {
    const { trackFlagsEvent } = useFlagsContext();

    const onSelectProduct = async (
        product: Product,
        dayPassStartDate?: DayPassAvailability,
    ) => {
        resetCookieForJoinJourney();

        await handleProductSelection(
            product,
            branchId,
            gymName,
            branchStatus,
            dayPassStartDate,
            trackFlagsEvent,
        );
    };

    useEffect(() => {
        trackFlagsEvent(OptimizelyEvents.VISIT_GYM_PAGE);
    }, [trackFlagsEvent]);

    const resetCookieForJoinJourney = () => {
        const shoppingBasketCookie = getShoppingBasketCookie();

        updateShoppingBasketCookie({
            ...getLoggerPaymentData({
                basketCookie: shoppingBasketCookie,
            }),
            branchId,
            branchName: gymName,
            // Avoids pending 9000 cases
            // Retained so that BE can re-use the previously generated memberID.
            memberId: shoppingBasketCookie?.memberId,
            transactionId: shoppingBasketCookie?.transactionId,
        });

        trackFlagsEvent(OptimizelyEvents.START_JOIN_JOURNEY);
    };

    useEffect(() => {
        const isPinoGym = products.some(
            ({ description }) =>
                description === 'Ultimate Monthly' ||
                description === 'Standard Monthly' ||
                description === 'Off Peak Monthly',
        );

        const monthlyProducts = !isPinoGym
            ? products.filter(
                  product =>
                      product.variant === ProductVariant.Monthly ||
                      product.variant === ProductVariant.Fixed,
              )
            : products.filter(
                  product => product.variant === ProductVariant.Monthly,
              );
        const listOfProducts = monthlyProducts.map(product => {
            const { description, expectedPaymentFrequency, price } = product;
            return {
                name: description,
                price,
                brand: gymName,
                category: expectedPaymentFrequency,
                variant: 'Create',
                quantity: 1,
            };
        });
        dispatchEvent<ProductViewEvent>(EventKey.PRODUCT_DETAIL, {
            event: EventKey.PRODUCT_DETAIL,
            ecommerce: {
                detail: {
                    products: listOfProducts,
                },
            },
        });
    }, [gymName, products]);

    const mappedProducts = formatProductsForUI(products);
    const fromPrice = getFromPriceForProducts(mappedProducts);

    // TODO This logic will be removed once production gym content has been changed
    const branchIdParameter =
        !!gym && gym.branchId !== '' ? gym.branchId : branchId;

    // TODO This logic will be removed once production gym content has been changed
    const gymNameParameter =
        !!gym && gym.gymName !== '' ? gym.gymName : gymName;

    const membershipContextualProperties = {
        products: mappedProducts,
        text: 'Our Memberships',
        description: '',
        ctaHref: '/join-now/your-membership/',
        ctaLabel: 'Join Now',
        onSelect: onSelectProduct,
        productsAvailableAddons,
        openingHours,
        isKickerOffersRibbonEnabled,
        offSale,
        branchId,
    };

    const showOffPeakTimes =
        products.some(({ description }) => description.includes('Off Peak')) &&
        offPeakTimes &&
        offPeakTimes.some(times =>
            Object.entries(times).some(([, value]) => value.length > 0),
        );

    const contextualProperties = {
        Generic: {
            branchId: branchIdParameter,
            gymName: gymNameParameter,
            page: CmsPage.GymPage,
        },
        Membership: membershipContextualProperties,
        GymLocation: {
            address,
            openingHours,
            latitude,
            longitude,
            offPeakTimes,
            showOffPeakTimes,
        },
        GymHeader: {
            trustpilotData,
            address,
            openingHours,
            latitude,
            longitude,
            fromPrice,
            gymRating,
            isGoogleRatingEnabled: GOOGLE_RATING_WIDGET_ENABLED,
            onCtaClick: resetCookieForJoinJourney,
        },
    };

    return (
        <>
            {content.map(contentItem => (
                <CmsContent
                    /* eslint-disable-next-line no-underscore-dangle */
                    key={uuidv4()}
                    schema={contentItem}
                    contextualProps={contextualProperties}
                />
            ))}
        </>
    );
}

export async function getServerSideProps(
    context: GetServerSidePropsContext<{ branchId: string; placeId?: string }>,
): Promise<{
    props: Pick<
        GymPageProperties,
        | 'branchId'
        | 'branchStatus'
        | 'gymName'
        | 'products'
        | 'openingHours'
        | 'address'
        | 'latitude'
        | 'longitude'
        | 'trustpilotData'
        | 'productsAvailableAddons'
        | 'offPeakTimes'
        | 'isKickerOffersRibbonEnabled'
        | 'gymRating'
        | 'offSale'
    >;
}> {
    const {
        publicRuntimeConfig: { APP_BASE_PATH },
        serverRuntimeConfig: {
            APP_INTERNAL_BASE_URL,
            TRUSTPILOT_API_KEY,
            GOOGLE_MAPS_API_SERVER_SIDE_KEY,
        },
    }: LocalConfig = getConfig();

    const domain = `${APP_INTERNAL_BASE_URL}${APP_BASE_PATH}`;
    const { params } = context;
    const branchId = params?.branchId as string;

    const gym = await axiosInstance.get<any, AxiosResponse<GymDto>>(
        `${domain}/api/proxy/gyms/${branchId}/`,
    );

    let products: GymProduct[] = [];

    if (!gym.data.offSale) {
        const optionsResponse = await axiosInstance.get<
            { BranchId: string },
            AxiosResponse<JoinOptionsResponse>
        >(`${domain}/api/proxy/join/options/gym/`, {
            params: {
                BranchId: branchId,
            },
        });
        products = optionsResponse.data.products;
    }

    const trustpilotData = await getTrustpilotData(TRUSTPILOT_API_KEY);
    const trustpilotDataProperty =
        trustpilotData !== null ? { trustpilotData } : {};

    let rating: PlaceRating | null = null;
    if (GOOGLE_RATING_WIDGET_ENABLED && params?.placeId) {
        rating = await getPlaceRating(
            params.placeId,
            GOOGLE_MAPS_API_SERVER_SIDE_KEY,
            serverLogger,
            serverApplicationIdentifier,
        );
    }
    const gymRating =
        rating && rating.rating >= MINIMUM_RATING_TO_SHOW ? rating : null;

    const productsAvailableAddons = await getProductsAvailableAddons(
        branchId,
        products,
    );

    // TODO: Remove this once kicker offers are enabled
    const isKickerOffersRibbonEnabled = false;

    return {
        props: {
            branchId: gym.data.branchId,
            gymName: gym.data.name,
            products,
            address: gym.data.address,
            openingHours: gym.data.openingHours,
            latitude: gym.data.latitude,
            longitude: gym.data.longitude,
            productsAvailableAddons,
            offPeakTimes: gym.data.offPeakTimes,
            isKickerOffersRibbonEnabled,
            gymRating,
            offSale: gym.data.offSale,
            branchStatus: gym.data.status,
            ...trustpilotDataProperty,
        },
    };
}
