import { AxiosResponse } from 'axios';
import { GetServerSidePropsContext } from 'next';
import getConfig from 'next/config';
import { v4 as uuidv4 } from 'uuid';

import { useMapsGymClick } from '../../core/customHooks/useMapsGymClick';
import { formatAddress } from '../../core/dataManipulation';
import { LocalConfig } from '../../next.config.types';
import { Region, RegionPageProperties } from './RegionPage.types';
import { GymStatus } from '@tgg/common-types';
import { axiosInstance } from '@tgg/micro-services/axios';
import { ApiResponse, Gym } from '@tgg/services';
import { CmsContent } from '@tgg/ui';

const {
    publicRuntimeConfig: { GOOGLE_MAPS_API_KEY },
}: LocalConfig = getConfig();

export function RegionPage({
    regionName,
    gyms,
    content,
}: RegionPageProperties) {
    const onGymClick = useMapsGymClick();

    const contextualProperties = {
        GoogleMap: {
            regionName,
            apiKey: GOOGLE_MAPS_API_KEY,
            markerOptions: gyms,
            onGymClick,
        },
    };

    return (
        <>
            {content.map(contentItem => {
                return (
                    <CmsContent
                        /* eslint-disable-next-line no-underscore-dangle */
                        key={uuidv4()}
                        schema={contentItem}
                        contextualProps={contextualProperties}
                    />
                );
            })}
        </>
    );
}

export async function getServerSideProps(
    _context: GetServerSidePropsContext<{ slug: string[]; region: any }>,
) {
    const {
        publicRuntimeConfig: { APP_BASE_PATH },
        serverRuntimeConfig: { APP_INTERNAL_BASE_URL },
    }: LocalConfig = getConfig();
    const domain = `${APP_INTERNAL_BASE_URL}${APP_BASE_PATH}`;
    const gymsFromAPI: AxiosResponse<ApiResponse> = await axiosInstance.get(
        `${domain}/api/proxy/gyms/`,
        {
            params: {
                statuses: [
                    GymStatus.Open,
                    GymStatus.ComingSoon,
                    GymStatus.ClosedForMaintenance,
                ],
            },
        },
    );

    const { params } = _context;
    const regionContent = params?.region;

    /* eslint-disable unicorn/prefer-object-from-entries */
    const gymsInformationByBranchId: {
        [branchId: string]: Omit<Gym, 'BranchId'>;
    } = gymsFromAPI.data.Data.reduce((accumulator, currentGym) => {
        const { BranchId, ...gymInfo } = currentGym;
        return { ...accumulator, [BranchId.toLowerCase()]: gymInfo };
    }, {});

    const regionName = (regionContent as Region).region;
    const regionalGyms = (regionContent as Region).gyms;

    // Defensive code to ignore missing gyms
    const filteredGymsList = regionalGyms.filter(({ branchId }) => {
        const gymInfo = gymsInformationByBranchId[branchId.toLowerCase()];
        return !!gymInfo;
    });

    const gymsList = filteredGymsList.map(regionalGym => {
        const { branchId, gymPageURL } = regionalGym;
        // We are not validating the UUID in amplience, the line below ignores case sensitivity on the content
        const gymInfo = gymsInformationByBranchId[branchId.toLowerCase()];

        const { Latitude, Longitude, Name, LowestPrice, JoiningFee, Address } =
            gymInfo;

        const gymAddress = Address
            ? formatAddress(
                  Address.address1,
                  Address.address2,
                  Address.city,
                  Address.postcode,
              )
            : '';

        return {
            position: {
                lat: Latitude,
                lng: Longitude,
            },
            gym: {
                branchId: branchId.toLowerCase(),
                gymName: Name,
                gymAddress,
                lowestPrice: LowestPrice,
                joiningFee: JoiningFee,
                gymPageURL,
            },
        };
    });

    return {
        props: {
            regionName,
            gyms: gymsList,
        },
    };
}
