import { NextRouter } from 'next/router';

export const setInitialFilters = (
    filters: string[] | string,
    subcategories: string[],
) => {
    if (Array.isArray(filters)) {
        return filters.filter(value => subcategories.includes(value));
    }

    if (typeof filters === 'string' && subcategories.includes(filters)) {
        return [filters];
    }

    return [];
};

export const updateUrl = (router: NextRouter, filter: string[]) => {
    return router.replace(
        {
            pathname: router.pathname,
            query: { ...router.query, filter },
        },
        undefined,
        { shallow: true },
    );
};
