import { useEffect } from 'react';

import imageMobile from '../../public/ywnqh/images/kettlebells-mobile.png';
import imageDesktop from '../../public/ywnqh/images/kettlebells.png';
import { dispatchEvent, AnalyticsErrorEvent, EventKey } from '@tgg/services';
import { GenericErrorMessage, Layout } from '@tgg/ui';

export function FourOhFour() {
    useEffect(() => {
        dispatchEvent<AnalyticsErrorEvent>(EventKey.ERROR, {
            category: '0404',
            details: {
                title: '404 Error',
                description: 'Page not found',
            },
        });
    }, []);

    return (
        <Layout>
            <GenericErrorMessage
                mainHeading="Oops!"
                heading="Something’s missing"
                message="The page you are looking for can’t be found. Let us
                            get you back on track."
                primaryButtonText="go to homepage"
                primaryLink="/"
                secondaryLink="/find-a-gym"
                secondaryButtonText="find a gym"
                imageSourceDesktop={imageDesktop.src}
                imageSourceMobile={imageMobile.src}
            />
        </Layout>
    );
}
