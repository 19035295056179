/* istanbul ignore file */
export const mockDataRegionPage = {
    regionName: 'London',
    gyms: [
        {
            position: {
                // eslint-disable-next-line unicorn/numeric-separators-style
                lat: 51.507152,
                // eslint-disable-next-line unicorn/numeric-separators-style
                lng: -0.124959,
            },
            gym: {
                gymName: 'London Charing Cross',
                gymAddress:
                    '17-21 Northumberland Avenue, Greater London, WC2N 5EA',
                lowestPrice: 31.99,
                joiningFee: 10,
                gymPageURL: 'london-charing-cross',
            },
        },
        {
            position: {
                // eslint-disable-next-line unicorn/numeric-separators-style
                lat: 51.52425,
                // eslint-disable-next-line unicorn/numeric-separators-style
                lng: -0.124868,
            },
            gym: {
                gymName: 'London Bloomsbury',
                gymAddress: 'Coram Street, Greater London, WC1N 1HB',
                lowestPrice: 28.99,
                joiningFee: 10,
                gymPageURL: 'london-bloomsbury',
            },
        },
        {
            position: {
                // eslint-disable-next-line unicorn/numeric-separators-style
                lat: 51.500846,
                // eslint-disable-next-line unicorn/numeric-separators-style
                lng: -0.107087,
            },
            gym: {
                gymName: 'London Waterloo',
                gymAddress:
                    'Barons Place 195-203 Waterloo Road, London, SE1 8UX',
                lowestPrice: 23.99,
                joiningFee: 10,
                gymPageURL: 'london-waterloo',
            },
        },
        {
            position: {
                // eslint-disable-next-line unicorn/numeric-separators-style
                lat: 51.51878,
                // eslint-disable-next-line unicorn/numeric-separators-style
                lng: -0.17575,
            },
            gym: {
                gymName: 'London Paddington',
                gymAddress:
                    'The Gym Group Paddington 33 North Wharf Road\r\nMerchant Square, W2 1LA',
                lowestPrice: 24.99,
                joiningFee: 0,
                gymPageURL: 'london-paddington',
            },
        },
        {
            position: {
                // eslint-disable-next-line unicorn/numeric-separators-style
                lat: 51.51631,
                // eslint-disable-next-line unicorn/numeric-separators-style
                lng: -0.136987,
            },
            gym: {
                gymName: 'London Oxford Street',
                gymAddress:
                    '120 Oxford Street Entrance on Wells Street, London, W1D 1LT',
                lowestPrice: 34.99,
                joiningFee: 10,
                gymPageURL: 'london-oxford-street',
            },
        },
        {
            position: {
                // eslint-disable-next-line unicorn/numeric-separators-style
                lat: 51.509046,
                // eslint-disable-next-line unicorn/numeric-separators-style
                lng: -0.084987,
            },
            gym: {
                gymName: 'London Monument',
                gymAddress:
                    'Northern and Shell Building 10 Lower Thames Street, EC3R 6EN',
                lowestPrice: 34.99,
                joiningFee: 10,
                gymPageURL: 'london-monument',
            },
        },
        {
            position: {
                // eslint-disable-next-line unicorn/numeric-separators-style
                lat: 51.51698,
                // eslint-disable-next-line unicorn/numeric-separators-style
                lng: -0.107496,
            },
            gym: {
                gymName: 'London Holborn Circus',
                gymAddress: '1 Thavies Inn, Holborn, Greater London, EC4A 1AN',
                lowestPrice: 32.99,
                joiningFee: 10,
                gymPageURL: 'london-holborn-circus',
            },
        },
    ],
};
