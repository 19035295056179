import { ProductVariant } from '@tgg/common-types';

const mockAddress = {
    address1: 'C1 - C2 The Galaxy',
    address2: 'Bridge Street',
    address3: 'Luton',
    city: 'Bedfordshire',
    postcode: 'LU1 2NB',
};

const mockProducts = [
    {
        id: '574b1671-eb23-4613-8fd5-3a12dd6caf9b',
        variant: ProductVariant.Monthly,
        joiningFee: '5.00',
        price: 17.99,
        description: 'DO IT.',
        expectedPaymentFrequency: 'Monthly',
        termType: 'Months',
        term: 0,
        tggMembershipType: 'Do_It',
        dayPassDays: [],
    },
    {
        id: '674b1671-eb23-4613-8fd5-3a12dd6caf9b',
        variant: ProductVariant.Monthly,
        joiningFee: '1.00',
        price: 19.99,
        description: 'LIVE IT.',
        expectedPaymentFrequency: 'Monthly',
        termType: 'Months',
        term: 0,
        tggMembershipType: 'Live_It',
        dayPassDays: [],
    },
];

const mockOpeningHours = {
    Sunday: {
        OpeningTime: '08:00:00',
        ClosingTime: '20:00:00',
    },
    Monday: {
        OpeningTime: '04:00:00',
        ClosingTime: '23:59:59',
    },
    Tuesday: {
        OpeningTime: '00:00:00',
        ClosingTime: '23:59:59',
    },
    Wednesday: {
        OpeningTime: '00:00:00',
        ClosingTime: '23:59:59',
    },
    Thursday: {
        OpeningTime: '00:00:00',
        ClosingTime: '23:59:59',
    },
    Friday: {
        OpeningTime: '00:00:00',
        ClosingTime: '22:00:00',
    },
    Saturday: {
        OpeningTime: '08:00:00',
        ClosingTime: '20:00:00',
    },
};

export const mockData = {
    branchId: 'A65A67E3-7DF8-487D-959C-69961D78A99E',
    gymName: 'Strood',
    products: mockProducts,
    address: mockAddress,
    openingHours: mockOpeningHours,
    latitude: 51.881_586,
    longitude: -0.418_496,
};
