import {
    FindBusinessUnitResponse,
    Link,
    StarsImageResourceResponse,
    StarsStringResourceResponse,
    TrustpilotResponses,
    TrustpilotData,
} from './trustpilot.types';
import { createApiService } from '@tgg/util';

export async function getTrustpilotData(
    trustpilotApiKey: string,
): Promise<TrustpilotData | null> {
    try {
        const businessUnit = (await getTrustpilotApi(
            'https://api.trustpilot.com/v1/business-units/find?name=thegymgroup.com',
            trustpilotApiKey,
        )) as FindBusinessUnitResponse;

        const { href: starsImageResourceUrl } = businessUnit.links.find(
            link => link.rel === 'resources-images-stars',
        ) as Link;

        const {
            starSvg: { url: starsImageUrl },
        } = (await getTrustpilotApi(
            starsImageResourceUrl,
            trustpilotApiKey,
        )) as StarsImageResourceResponse;

        const { href: starsStringResourceUrl } = businessUnit.links.find(
            link => link.rel === 'resources-strings-stars',
        ) as Link;

        const { string: starLabel } = (await getTrustpilotApi(
            starsStringResourceUrl,
            trustpilotApiKey,
        )) as StarsStringResourceResponse;

        return {
            starsImageUrl,
            starRatingNumber: businessUnit.score.stars,
            starLabel,
            totalNumberOfReviews: businessUnit.numberOfReviews.total,
        };
    } catch {
        return null;
    }
}

async function getTrustpilotApi(
    apiUrl: string,
    trustpilotApiKey: string,
): Promise<TrustpilotResponses> {
    const getTrustpilotApiService = createApiService<
        'get',
        TrustpilotResponses
    >('get', {
        path: apiUrl,
        config: { headers: { apiKey: trustpilotApiKey } },
    });

    const { data } = await getTrustpilotApiService({
        apiConfig: {
            url: '',
            subscriptionKey: '',
        },
    });

    return data.data;
}
