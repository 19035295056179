export const formatAddress = (
    address1: string,
    address2: string | null,
    city: string | null,
    postcode: string,
) => {
    const addressArray = [`${address1}${address2 ? ` ${address2}` : ''}`];
    if (city) addressArray.push(city);
    addressArray.push(postcode);
    return addressArray.join(', ');
};
