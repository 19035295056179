import { useMediaQuery } from '@mui/material';
import { AxiosResponse } from 'axios';
import { GetServerSidePropsContext } from 'next';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { setInitialFilters, updateUrl } from './BlogCategoryPage.helpers';
import { type BlogCategoryPageProperties } from './BlogCategoryPage.types';
import { BreadcrumbsElement, StoryBoxProperties } from '@tgg/common-types';
import { axiosInstance } from '@tgg/micro-services/axios';
import { CmsClient } from '@tgg/micro-services/cms-client';
import { clientLogger } from '@tgg/services';
import {
    BlogFilters,
    BlogPostsList,
    CmsContent,
    Breadcrumbs,
    theme,
} from '@tgg/ui';
import { getBlogHomePage, generateErrorMessage } from '@tgg/util';
import { applicationIdentifier } from 'apps/commerce/core/constants';
import { serverLogger } from 'apps/commerce/core/serverLogger';
import { LocalConfig } from 'apps/commerce/next.config.types';

const {
    publicRuntimeConfig: { APP_BASE_PATH, AMPLIENCE_HUB_ID },
    serverRuntimeConfig: {
        APP_INTERNAL_BASE_URL,
        AMPLIENCE_FRESH_API_KEY,
        AMPLIENCE_FRESH_API_ON,
    },
}: LocalConfig = getConfig();

export function BlogCategoryPage({
    category,
    titleBox,
    posts: initialPosts,
    subcategories,
    breadcrumbElements,
}: BlogCategoryPageProperties) {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

    const router = useRouter();

    const [posts, setPosts] = useState(initialPosts);

    const [filters, setFilters] = useState(
        router.query.filter
            ? setInitialFilters(router.query.filter, subcategories)
            : [],
    );
    const [isFiltersApplied, setIsFiltersApplied] = useState(
        !!router.query.filter,
    );

    const handleSubmit = async (values: string[]) => {
        setFilters(values);

        try {
            const blogPostsCmsUrl = `/api/cms/blog/posts-by-category/${encodeURIComponent(
                category.name,
            )}`;

            const { data }: AxiosResponse<StoryBoxProperties[]> =
                await axiosInstance.get(blogPostsCmsUrl, {
                    params: {
                        filters: values,
                    },
                });

            await updateUrl(router, values);

            setPosts(data);
            setIsFiltersApplied(values.length > 0);
        } catch (error) {
            clientLogger.error(
                `Blog Filter Overlay Form Unable to get posts - ${
                    (error as Error).message
                }`,
                applicationIdentifier,
            );
        }
    };

    const handleRemoveFilter = async (filter: string) => {
        setFilters(filters.filter(value => value !== filter));

        await handleSubmit(filters.filter(value => value !== filter));
    };

    return (
        <>
            {titleBox && <CmsContent schema={titleBox} />}
            {isDesktop && breadcrumbElements && (
                <Breadcrumbs elements={breadcrumbElements} />
            )}
            <BlogFilters
                filters={subcategories}
                currentFilters={filters}
                onRemoveFilter={handleRemoveFilter}
                handleSubmit={handleSubmit}
                categoryName={category.name}
            />
            {posts && (
                <BlogPostsList
                    posts={posts}
                    isFiltersApplied={isFiltersApplied}
                />
            )}
        </>
    );
}

export const getServerSideProps = async (
    _context: GetServerSidePropsContext,
) => {
    const domain = `${APP_INTERNAL_BASE_URL}${APP_BASE_PATH}`;
    const { params, query } = _context;
    const { category, ...restParameters } = params as unknown as Omit<
        BlogCategoryPageProperties,
        'posts' | 'breadcrumbElements'
    >;
    const categoryName = category.name;

    try {
        const postsCmsPromise: Promise<AxiosResponse<StoryBoxProperties[]>> =
            axiosInstance.get(
                `${domain}/api/cms/blog/posts-by-category/${categoryName}`,
                {
                    params: {
                        filters: query?.filter
                            ? Array.isArray(query?.filter)
                                ? query.filter
                                : [query.filter]
                            : [],
                    },
                },
            );

        const cmsClient = new CmsClient({
            hubName: AMPLIENCE_HUB_ID,
            freshApiKey: AMPLIENCE_FRESH_API_KEY,
            isFreshOn: AMPLIENCE_FRESH_API_ON,
        });

        const getBlogHomePagePromise = getBlogHomePage(cmsClient);

        const subcategoriesCmsPromise: Promise<AxiosResponse<string[]>> =
            axiosInstance.get(
                `${domain}/api/cms/blog/subcategories-by-category/${categoryName}`,
            );

        const [{ data: posts }, { data: subcategories }, blogHomePage] =
            await Promise.all([
                postsCmsPromise,
                subcategoriesCmsPromise,
                getBlogHomePagePromise,
            ]);

        const breadcrumbElements: BreadcrumbsElement[] = [
            {
                label: (blogHomePage.titleBox?.text || 'blog') as string,
                // eslint-disable-next-line no-underscore-dangle
                url: blogHomePage._meta.deliveryKey,
            },
            {
                label: categoryName,
            },
        ];

        return {
            props: {
                ...restParameters,
                category,
                posts,
                subcategories,
                breadcrumbElements,
            },
        };
    } catch (error) {
        return generateErrorMessage(error, serverLogger, {
            page: 'BlogCategoryPage',
            endpointName: `/api/cms/blog/posts-by-category/${categoryName}`,
        });
    }
};
